// Pricing.js

import React from 'react';

const pricingPlans = [
  {
    name: 'Astroid Booster Perks',
    features: [
      'Booster domains',
      '20GBIT server (legit better)',
      'Beta access (updates faster)',
      'Access to 60s cooldown link generator API',
      'Link generator on web (coming soon)',
      'Booster link bot',
      'My love',
    ],
    buttonText: 'Boost the server',
  },
  {
    name: 'Astroid Purple',
    price: '$4.99 Per Month',
    features: [
      'Premium domains',
      '20GBIT server (legit better)',
      'Better roblox bypass',
      'Access to the premium BYOD server',
      'Beta access (updates faster)',
      'Access to 5s cooldown link generator API',
      'Link generator on web (coming soon)',
      'Premium link bot',
      'Help me buy domains and keep Astroid up',
      'My infinite love',
    ],
  },
  {
    name: 'Astroid Purple Pro',
    price: '$9.99 Per Month',
    features: [
      'Premium domains',
      '20GBIT server (legit better)',
      'Better roblox bypass',
      'Access to the premium BYOD server',
      'Kasm access (Remote desktop, games, browsers)',
      'Beta access (updates faster)',
      'Access to 2s cooldown link generator API',
      'Link generator on web (coming soon)',
      'Premium link bot',
      'Voice message from <@747855409661870130> saying anything you want',
      'Astroid VPN Unlimited (soon??)',
      'Astroid DNS',
      'Help me buy domains and keep Astroid up',
      'My infinite love',
    ],
  },
  {
    name: 'Astroid Purple Pro Max',
    price: '$24.99 Per Month',
    features: [
      'Premium domains',
      '20GBIT server (legit better)',
      'Better roblox bypass',
      'Access to the premium BYOD server',
      'Kasm access (Remote desktop, games, browsers)',
      'Beta access (updates faster)',
      'Access to no cooldown link generator API',
      'Link generator on web (coming soon)',
      'Premium link bot',
      'Voice message from <@747855409661870130> and <@657853890145943553>',
      'Astroid VPN Unlimited With optimised route and speed! (soon??)',
      'Astroid DNS',
      'Private Astroid instance on your own 15 - 20GBIT server (not joking, dm for more information)',
      'Access to new services earlier',
      'Even more private domains (better api with more domains included)',
      'Full Astroid Astroid db dump (for legal reasons, this is a joke)',
      'Help me buy a ton of domains and keep Astroid up',
      'My infinite love (forever)',
      'I will actually love u forever',
    ],
  },
];

const Pricing = () => {
  return (
    <div className="min-h-full">
      <div className="bg-indigo-600">
        {/* Header Section (remind me to add evebntually) */}        
      </div>

      {/* Pricing Plans Section */}
      <div className="py-12 bg-gray-100 dark:bg-slate-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-indigo-500 font-semibold tracking-wide uppercase">Choose your plan</h2>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {pricingPlans.map((plan, index) => (
              <div key={index} className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">{plan.name}</h3>
                {plan.price && <p className="text-xl font-semibold text-indigo-600 mb-4">{plan.price}</p>}
                <ul className="text-gray-600 dark:text-gray-400 mb-6">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="mb-2">
                      {feature}
                    </li>
                  ))}
                </ul>
                <button   onClick={() => window.location.href = 'https://donate.astroid.gg'}
className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700">
                  {plan.buttonText || 'Get Started'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer class="p-4 bg-white sm:p-6 dark:bg-gray-800">
              <div class="max-w-7xl mx-auto py-12 px-6 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                  <div class="mb-6 md:mb-0">
                      <a href="https://vyper.group" class="flex-shrink-0 flex items-center">
                          <img src="https://cdn.discordapp.com/attachments/877373795348906024/991358750227169290/Bl.png?ex=655cc748&is=654a5248&hm=6022e457e9d3fe33c129bc7d4711b56d76e67d7dc10e32a8598e328cfaab9757&" class="mr-3 h-8" alt="Vyper Group Logo" />
                          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Vyper Group</span>
                      </a>
                  </div>
                  <br></br>
                  <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                      <div>
                          <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Links</h2>
                          <ul class="text-gray-600 dark:text-gray-400">
                              <li class="mb-4">
                                  <a href="https://discord.gg/9sUDHyGQfC" class="hover:underline">Discord</a>
                              </li>
                              <li>
                                  <a href="https://github.com/VyperGroup" class="hover:underline">Github</a>
                              </li>
                          </ul>
                      </div>
                      <div>
                          <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Our Projects</h2>
                          <ul class="text-gray-600 dark:text-gray-400">
                              <li class="mb-4">
                                  <a href="https://astroid.gg" class="hover:underline">Astroid V3</a>
                              </li>
                              <li>
                                  <a href="https://astroidlite.vyper.group" class="hover:underline">Astroid Lite</a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
              <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">Copyright © 2022 <a href="https://vyper.group" class="hover:underline">Vyper Group LTD</a>. All Rights Reserved.
              </span>
          </footer> 
    </div>
  );
};

export default Pricing;
